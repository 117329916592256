.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.25rem 0;
}

.pagination .page-item {
  width: 2.1875rem;
  height: 2.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.3125rem;
  padding: 0.3125rem;
  background-color: var(--white);
  border: 0.0625rem solid var(--tertiary-dark);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.pagination .page-item:hover {
  background-color: var(--tertiary-dark);
  border-color: var(--secondary-light);
}

.pagination .page-item.active {
  background-color: var(--primary-main);
  border-color: var(--primary-main);
  color: var(--white);
  
}

.pagination .page-item.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.pagination .page-link {
  width: 100%;
  height: 100%;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}


.pagination .page-link:hover {
  text-decoration: none;
}

.pagination .page-link svg {
  vertical-align: middle;
}

.pagination .previous {
  order: 1;
}

.pagination .next {
  order: 3;
}

.pagination li:first-child .page-link,
.pagination li:last-child .page-link {
  border-radius: 0.25rem;
}
